import { useEffect, useState, useRef } from 'react'
import './Cards.css';

function Cards() {

    const [data, setData ] = useState([])

    const carousel = useRef(null);
    
    useEffect(() => {
        fetch('https://adolfogarcia.com.br/static/cards.json')
        .then((response) => response.json())
        .then(setData);
    }, []);

    const handleLeftClick = (e) => {
        e.preventDefault();
        carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }

    const handleRightClick = (e) => {
        e.preventDefault();
        carousel.current.scrollLeft += carousel.current.offsetWidth;
    }

    if(!data || !data.length) return null;

    return (

        <div className='container-cards'>
            <div className='buttons'>
                <button onClick={handleLeftClick}>
                    <img className='button-left' src="/static/images/chevron.png" alt="Scroll Esquerdo"/>
                </button>
                </div>
                    <div className='carousel' ref={carousel}>
                        {data.map((item) => {
                            const {id, icon, title, arrow, desc} = item;
                                return (
                                    <div className='cards-body' key={id}>
                                        <div className='cards-img'>
                                            <img src={icon} alt={title}/>
                                        </div>
                                        <div className='cards-title'>
                                        <h5>{title}</h5>
                                        </div>
                                        <div className='icon-more'>
                                            <img src={arrow} alt="ler mais"/>
                                        </div>
                                        <p className='cards-description'>{desc}</p>
                                    </div>
                                );
                        })}
                    </div>
                <div className='buttons'>
                    <button onClick={handleRightClick}>
                        <img className='button-right' src="/static/images/chevron.png" alt="Scroll Direito"/>
                    </button>
                </div>
        </div>
    )
}


export default Cards;