import './Footer.css';

export default function Footer() {
    return (
        <>
            <footer id='footer' className="footer">
				<div className="inner">
					<div className="content">
						<section>
							<h4>Advogado Adolfo Garcia</h4>
							<p>Escritório de advocacia especializado em Direito Trabalhista</p>
						</section>
						<section>
							<h4>Contatos</h4>
							<ul className="alt">
								<li><a href="https://www.google.com/maps?ll=-23.658821,-46.529321&z=16&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=R.+%C3%81lvares+de+Azevedo,+210+-+Centro+Santo+Andr%C3%A9+-+SP+09020-140">R. Álvares de Azevedo, 210, 9° Andar, Sala 92 - Santo André - SP.</a></li>
								<li><a href="#" >(11) 4994-9800</a></li>
								<li><a >adolfogarcia.adv@gmail.com</a></li>
							</ul>
						</section>
					</div>
					<div className="copyright">
						<a href='https://www.linkedin.com/in/joaomarcos-silva/' target='_blank' rel="noreferrer">&copy; Developed by João Marcos</a>	
					</div>
				</div>
			</footer>
        </>
    )
}