import './Home.css';

export default function Home() {
    return (
        <section id='home' className="home">
            <div className='container'>
                <h1>ADOLFO GARCIA</h1>
                <h4>Escritório de advocacia</h4>
                <button className='btn'><a href='https://wa.me/5511999929154?text=Ol%c3%a1+Adolfo+Garcia%2c+tudo+bem%3f+Gostaria+de+fazer+um+or%c3%a7amento.' target='_blank'>Clique Aqui</a></button>
                
            </div>
        </section>
    )
}