import Cards from './Cards.js'
import './Areas.css'

export default function Areas() {
    return (
        <div id='areas' className="areas">
            <div className="container">
                <h4>Áreas de Atuação</h4>
                <p>O escritório Adolfo Garcia Advogados Associados é especializado na assessoria jurídica de empresas de pequeno, médio e grande porte, desempenhando cuidadoso acompanhamento tanto no âmbito preventivo quanto contencioso e aconselhamento, com especial ênfase na condução de negociações e litígios corporativos, todavia, primando sempre pelo relacionamento individualizado com nossos clientes.
                </p>
        
                <Cards />

                <p>Adolfo Garcia Advogados Associados relaciona-se em colaboração com escritórios em todos os estados e principais cidades brasileiras, possibilitando a extensão de sua atuação para uma eficaz performance, seja no aspecto intelectual, seja no aspecto prático do direito.</p>

                <p>Para isso contamos com profissionais experientes, aptos a realizar um atendimento de alta qualidade, com o objetivo primordial de assessorar sua empresa nas seguintes esferas:</p>
            </div>
        </div>
    )
}

