import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Budgets.css';


export default function Budgets() {

    const formContact = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('gmailMessage', 'template_xa5jyot', formContact.current, 'lDecaf-WWlLV3oT44')
            .then((result) => {
                alert('Mensagem enviada com sucesso!');
            }, (error) => {
                alert(error.message);
            });
            e.target.reset();
    }

    return (
        <div id='budgets' className="budgets">
            
            <div className='container'> 
                <h4>Orçamento</h4>

                <p>Preencha o formulário e faça seu orçamento.</p>
            </div>
            
            <div className='container-forms-maps'>

            <form ref={formContact} onSubmit={sendEmail} className="contact__form">
                
                <input type="text" placeholder="Nome Completo" name="name" className='field' required/>
                
                <input type="email" placeholder="Email" name="email" className='field' required/>

                <input type="tel" placeholder="Telefone" name="telephone" className='field' required/>

                <input type="text" placeholder="Endereço" name="address" className='field' />

                <input type="text" placeholder="CPF" name="CPF" className='field' /> 

                <input type="text" placeholder="RG" name="RG" className='field' /> 

                <input type="text" placeholder="Assunto" name="subject" className='field' required/>

                <textarea name="message" id="" class="field" placeholder="Mensagem" required></textarea>

                <button type="submit" className='button' value="Send">Enviar</button>
                
            </form>

            <iframe title='Local do Escritório' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7308.95807868406!2d-46.529321!3d-23.658821!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce428ec7da02f7%3A0x630d2955e971c748!2sR.%20%C3%81lvares%20de%20Azevedo%2C%20210%20-%20Centro%2C%20Santo%20Andr%C3%A9%20-%20SP%2C%2009020-140!5e0!3m2!1spt-BR!2sbr!4v1652143201902!5m2!1spt-BR!2sbr" 
            style={{ border: "0" }}  
            loading="lazy"
            />

            </div>
        </div>

    )

    
}