import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Areas from './components/Areas/Areas';
import Budgets from './components/Budgets/Budgets';
import Footer from './components/Footer/Footer';
import Whatsapp from './components/Whatsapp/Whatsapp';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Areas />
      <Budgets />
      <Footer />
      <Whatsapp />
    </div>
  );
}

export default App;
