export const MenuItems = [
    {
        title: 'Home',
        url: '#home',
        cName: 'nav-links'
    },
    {
        title: 'Escritório',
        url: '#about',
        cName: 'nav-links'
    },
    {
        title: 'Área de Atuação',
        url: '#areas',
        cName: 'nav-links'
    },
    {
        title: 'Orçamento',
        url: '#budgets',
        cName: 'nav-links'
    }
]