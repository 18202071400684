import './About.css'
import { useState } from "react";

import Office from '../../images/advocacia.png'

export default function About() {

    const [show, setShow] = useState(false)

    return (
        <div id='about' className='about'>
            <div className='container'>
                <h4>Adolfo garcia</h4>
                <p>Fundado em 1985 pelo advogado Adolfo Alfonso Garcia, que o nomeia, o escritório Adolfo Garcia Advogados Associados é referência em direito, contando desde seu início com uma carteira de clientes formada por nomes reconhecidos mundialmente.</p>
                {
                    show ? <> <p>Nosso objetivo principal sempre foi e será a busca da excelência no atendimento aos clientes e, para atingi-lo, desenvolvemos a cada dia soluções criativas e eficazes para as mais variadas hipóteses jurídicas, das simples e rotineiras às mais complexas e sofisticadas.</p>

                        <p>E nosso compromisso vai além: temos orgulho da parceria formada com nossos clientes, a quem acompanhamos com esmero e dedicação, o que nos proporciona antecipar não só problemas, como também oportunidades em sua solução.</p>

                        <p>Consideramos que o maior patrimônio do escritório resulta da união do talento e dedicação de nossos advogados, e do relacionamento desenvolvido com nossos clientes, dois pontos relevantes que, em conjunto, diferenciam o trabalho executado por Adolfo Garcia Advogados Associados.</p>

                        <p>Nossa atuação esta pautada pelo absoluto compromisso com princípios éticos, pela qualidade das relações humanas e pelo dever de contribuir para o desenvolvimento e aperfeiçoamento da sociedade.</p>

                        <p>Adolfo Garcia Advogados Associados inspira-se em sua história de mais de trinta e um anos para continuar a trilhar seu caminho, voltado para a construção e o aprimoramento, dia a dia, de uma advocacia de resultados, amparada em rígidos princípios éticos e na experiência e qualificação de sua equipe.</p>

                        <p> A meta que nos impulsiona é preservar, dentre tantos e significativos valores repassados a seus advogados, o mesmo espírito, fundado na justiça e busca incessante do direito, do seu fundador.</p>
                         
                        <img className='office' src={Office} alt="Imagens do Escritório" />
                        
                        </> : null
                }

                <button className='learn-more' onClick={() => setShow(!show)}>{show ? "Ver menos" : "Ver mais"}</button>
                
            </div>
        </div>
    )
}