import "./Whatsapp.css";
import { FaWhatsapp } from "react-icons/fa";

export default function Whatsapp() {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=5511949949800&text=Ol%C3%A1+Adolfo+Garcia%2C+tudo+bem%3F+Gostaria+de+fazer+um+or%C3%A7amento.&type=phone_number&app_absent=0";

  return (
    <div>
      <div className="div-whatsapp">
        <a href={whatsappLink} target="_blank" rel="noreferrer">
          <FaWhatsapp color="white" size={40} />
        </a>
        <div className="div-text">
          <p>Clique e entre em contato com o seu advogado agora!</p>
        </div>
      </div>
    </div>
  );
}
